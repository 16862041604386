body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0A192F;
}

.select-box-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select-box {
  width: 50%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* folded phones */
@media only screen and (max-width: 300px) {
  .select-box {
    width: 90%;
  }
}

/* regular phones */
@media only screen and (min-width: 301px) and (max-width: 400px) {
  .select-box {
    width: 76%;
  }
}

/* wider phones */
@media only screen and (min-width: 401px) and (max-width: 450px) {
  .select-box {
    width: 66%;
  }
}

/* tablet or rotated phones */
@media only screen and (min-width: 451px) and (max-width: 600px) {
  .select-box {
    width: 56%;
  }
}

/* wider tablet */
@media only screen and (min-width: 601px) and (max-width: 700px) {
  .select-box {
    width: 46%;
  }
}

/* rotated small tablet */
@media only screen and (min-width: 701px) and (max-width: 800px) {
  .select-box {
    width: 40%;
  }
}

/* wide tablet */
@media only screen and (min-width: 801px) and (max-width: 850px) {
  .select-box {
    width: 36%;
  }
}

/* iphone xr */
@media only screen and (min-width: 851px) and (max-width: 900px) {
  .select-box {
    width: 32%;
  }
}

/* rotated iphone pro max */
@media only screen and (min-width: 901px) and (max-width: 1000px) {
  .select-box {
    width: 32%;
  }
}

/* larger tablets */
@media only screen and (min-width: 1001px) and (max-width: 1100px) {
  .select-box {
    width: 30%;
  }
}

/* larger screens */
@media only screen and (min-width: 1101px) and (max-width: 1400px) {
  .select-box {
    width: 360px;
    font-size: 20px;
  }
}

/* regular */
@media only screen and (min-width: 1401px) {
  .select-box {
    width: 380px;
    font-size: 20px;
  }
}