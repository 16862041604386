.film-list {
  width: 96vw;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* regular */
@media only screen and (min-width: 1401px) {
  .film-list {
    width: 70vw;
  }
}