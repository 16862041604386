.film-search-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3px 6px;
}

.search-icon {
    height: 18px;
    width: 18px;
    border-radius: 2px;
}

.search-icon:hover {
    transform: scale(1.20);
}

/* wider phones */
@media only screen and (min-width: 401px) and (max-width: 450px) {
    .search-icon {
        height: 24px;
        width: 24px;
        border-radius: 3px;
    }
}

/* tablet or rotated phones */
@media only screen and (min-width: 451px) and (max-width: 600px) {
    .search-icon {
        height: 20px;
        width: 20px;
        border-radius: 2px;
    }
}

/* wider tablet */
@media only screen and (min-width: 601px) and (max-width: 700px) {
    .search-icon {
        height: 18px;
        width: 18px;
        border-radius: 2px;
    }
}

/* rotated small tablet */
@media only screen and (min-width: 701px) and (max-width: 800px) {
    .search-icon {
        height: 20px;
        width: 20px;
        border-radius: 3px;
    }
}

/* wide tablet */
@media only screen and (min-width: 801px) and (max-width: 850px) {
    .search-icon {
        height: 22px;
        width: 22px;
        border-radius: 3px;
    }
}

/* iphone xr */
@media only screen and (min-width: 851px) and (max-width: 900px) {
    .search-icon {
        height: 22px;
        width: 22px;
        border-radius: 3px;
    }
}

/* rotated iphone pro max */
@media only screen and (min-width: 901px) and (max-width: 1000px) {
    .search-icon {
        height: 23px;
        width: 23px;
        border-radius: 3px;
    }
}

/* larger tablets */
@media only screen and (min-width: 1001px) and (max-width: 1100px) {
    .search-icon {
        height: 22px;
        width: 22px;
        border-radius: 3px;
    }
}

/* larger screens */
@media only screen and (min-width: 1101px) and (max-width: 1400px) {
    .search-icon {
        height: 27px;
        width: 27px;
        border-radius: 3px;
    }
}

/* regular */
@media only screen and (min-width: 1401px) {
    .search-icon {
        height: 25px;
        width: 25px;
        border-radius: 3px;
    }
}